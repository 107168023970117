import clsx from 'clsx';

interface IProps {
  variant: 'landing' | 'game';
}

export const LearningLabLogo = ({ variant }: IProps) => {
  return (
    <img
      src="https://storage.googleapis.com/ledsp-props-and-tools-sets/hospitalia-staging/images/logo_llab_ext.avif"
      alt="Logo LLab"
      className={clsx(variant === 'game' ? 'w-3/6' : 'h-1/5')}
      style={{
        imageRendering: '-webkit-optimize-contrast',
      }}
    />
  );
};
