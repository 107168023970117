export const SDABocconiLogo = () => {
  return (
    <img
      src="https://storage.googleapis.com/ledsp-props-and-tools-sets/hospitalia-staging/images/logo_sda.avif"
      alt="Logo SDA"
      className="h-1/3"
      style={{
        imageRendering: '-webkit-optimize-contrast',
      }}
    />
  );
};
