import { LearningLabLogo } from './comps/assets/llab-logo';
import { SDABocconiLogo } from './comps/assets/sdabocconi-logo';
import { HomeHeader } from './comps/layout/home-header';
import { MainLayout } from './comps/layout/main';
import useGameQueryParams from './utils/useGameQueryParams.hook';

export const App = () => {
  const { player, team, teamMembers } = useGameQueryParams();

  return (
    <MainLayout>
      <HomeHeader>
        <SDABocconiLogo />
        <LearningLabLogo variant="landing" />
        <span />
      </HomeHeader>

      <div className="h-full flex flex-col gap-4 justify-center items-center">
        <h1 className="font-bold text-4xl">Welcome to DEMO SIM!</h1>
        <div>
          <p>
            Hi <span className="underline italic">{player.displayName}</span>{' '}
            (ID: {player.id}).
          </p>
          <br />
          <p>
            Your team is <span className="font-bold">{team.name}</span> with ID:{' '}
            {team.id}.
          </p>
          <br />
          <p>Your team members are:</p>
          <ul className="list-disc pl-10">
            {teamMembers.map(member => (
              <li key={member.id}>
                <span className="italic underline">{member.displayName}</span>{' '}
                (ID: {member.id})
              </li>
            ))}
          </ul>
        </div>

        <div className="mt-10 w-72 flex justify-between">
          <button className="bg-mainBlue-base text-white p-2 rounded-md hover:opacity-70">
            START GAME
          </button>
          <button className="bg-mainBlue-base text-white p-2 rounded-md hover:opacity-70">
            END GAME
          </button>
        </div>
      </div>
    </MainLayout>
  );
};
