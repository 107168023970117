import qs from 'query-string';
import { GamePlayPayload } from '../game-play/core';

const defaultGamePlayPayload: GamePlayPayload = {
  player: { id: '-null', displayName: 'null' },
  team: { id: '-null', name: 'null' },
  teamMembers: [],
  startEndpoint: '',
  finishEndpoint: '',
  endGameSurvey: '',
  endGameReturnPath: '',
};

const QSDecode = (qsString: string) => {
  let decoded: string;
  try {
    decoded = atob(qsString);
    return Object.assign(defaultGamePlayPayload, JSON.parse(decoded));
  } catch (e) {
    console.error('Error decoding query string in bse64!', e);
    return defaultGamePlayPayload;
  }
};

export default function useGameQueryParams(): GamePlayPayload {
  const parsed = qs.parse(location.search);
  const data = parsed?.data?.toString();

  if (data) return QSDecode(data);
  return defaultGamePlayPayload;
}
